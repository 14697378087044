import React, { type PropsWithChildren, useEffect, useState } from 'react';
import { z } from 'zod';

const themeSchema = z.object({
  iconColor: z.enum(['DARK', 'LIGHT']),
  surface: z.object({
    primary: z.string(),
    secondary: z.string(),
    tertiary: z.string(),
  }),
  content: z.object({
    primary: z.string(),
    secondary: z.string(),
    tertiary: z.string(),
    negative: z.string(),
    positive: z.string(),
  }),
});

interface ChartTheme {
  chart: {
    Background: {
      color: string
    }
    'Grid Lines': {
      color: string
      opacity: number
    }
    'Grid Dividers': {
      color: string
      opacity: number
    }
    'Axis Text': {
      color: string
    }
  }
  chartTypes: {
    'Candle/Bar': {
      up: {
        color: string
        wick: string
        border: string
      }
      down: {
        color: string
        wick: string
        border: string
      }
      even: {
        wick: string
      }
    }
    Line: {
      color: string
    }
    Mountain: {
      color: string
      basecolor: string
    }
  }
}

interface ThemeContext {
  chart: ChartTheme | null
}

export const themeContext = React.createContext<ThemeContext>({
  chart: null,
});

export default function Theme({ children }: PropsWithChildren): JSX.Element {
  const theme = new URLSearchParams(window.location.search).get('theme');

  const [chartTheme, setChartTheme] = useState<ChartTheme | null>(null);

  useEffect(() => {
    if (theme == null || theme === '') return;

    const jsonTheme = JSON.parse(theme);

    const themeParsed = themeSchema.safeParse(jsonTheme);

    console.log('themeParsed', themeParsed);
    if (!themeParsed.success) return;

    const themeData = themeParsed.data;

    const root = document.documentElement;

    root.style.setProperty(
      '--iconColor25',
      themeData.iconColor === 'DARK' ? '0px' : '25px',
    );
    root.style.setProperty(
      '--iconColor40',
      themeData.iconColor === 'DARK' ? '0px' : '40px',
    );
    root.classList.add(
      `iconColor-${themeData.iconColor === 'DARK' ? 'dark' : 'light'}`,
    );

    root.style.setProperty('--surfacePrimary', themeData.surface.primary);
    root.style.setProperty('--surfaceSecondary', themeData.surface.secondary);
    root.style.setProperty('--surfaceTertiary', themeData.surface.tertiary);

    root.style.setProperty('--contentPrimary', themeData.content.primary);
    root.style.setProperty('--contentSecondary', themeData.content.secondary);
    root.style.setProperty('--contentTertiary', themeData.content.tertiary);
    root.style.setProperty('--brandNegative', themeData.content.negative);
    root.style.setProperty('--brandPositive', themeData.content.positive);

    setChartTheme({
      chart: {
        Background: {
          color: themeData.surface.primary,
        },
        'Axis Text': {
          color: themeData.content.primary,
        },
        'Grid Dividers': {
          color: themeData.surface.tertiary,
          opacity: 0.5,
        },
        'Grid Lines': {
          color: themeData.surface.secondary,
          opacity: 0.5,
        },
      },
      chartTypes: {
        'Candle/Bar': {
          down: {
            color: themeData.content.negative,
            wick: themeData.content.negative,
            border: themeData.content.negative,
          },
          up: {
            color: themeData.content.positive,
            wick: themeData.content.positive,
            border: themeData.content.positive,
          },
          even: {
            wick: themeData.content.tertiary,
          },
        },
        Line: {
          color: themeData.content.primary,
        },
        Mountain: {
          basecolor: themeData.surface.primary + '30',
          color: themeData.content.primary,
        },
      },
    });
  }, [theme]);

  return (
    <themeContext.Provider
      value={{
        chart: chartTheme,
      }}
    >
      {children}
    </themeContext.Provider>
  );
}

export const useChartTheme = (): ChartTheme | null => {
  const { chart } = React.useContext(themeContext);
  return chart;
};
