import { z } from 'zod';

import { Account } from '../Account';
import { OrderValiditySchema, SubAccountIdSchema, UserAccountsSchema, userAccountsKey, sdk, trade, type Contract, type Order } from '@arlequin-finance/af-frontend-sdk';
import { CHARTIQ_LEVERAGE_INPUT_ATTRIBUTE_NAME } from '../../ChartObserver';

const CIQOrderActionSchema = z.enum(['buy', 'sell', 'short', 'cover']);
type CIQOrderAction = z.infer<typeof CIQOrderActionSchema>;

export const CIQOrderSchema = z.object({
  type: z.literal('order'),
  symbol: z.string(),
  action: CIQOrderActionSchema,
  quantity: z.number(),
  limit: z.number().optional(),
  stop: z.number().optional(),
  tif: OrderValiditySchema,
  oto: z
    .array(
      z
        .object({
          limit: z.number(),
        })
        .or(z.object({ stop: z.number() })),
    )
    .optional()
    .nullable(),
});

export type CIQOrder = z.infer<typeof CIQOrderSchema>;

function transformCIQOrderAction(action: CIQOrderAction): 'BUY' | 'SELL' {
  switch (action) {
    case 'buy':
    case 'short':
      return 'BUY';
    case 'sell':
    case 'cover':
      return 'SELL';
  }
}

export async function placeCIQOrder(
  tfc: unknown,
  order: unknown,
  cb: unknown,
  self: Account,
  orderLeverage?: number,
): Promise<void> {
  try {
    if (typeof cb !== 'function') throw new Error('callback is not a function');

    const ciqOrder = CIQOrderSchema.safeParse(order);

    if (!ciqOrder.success) throw new Error('invalid order schema');

    if (!tfc || typeof tfc !== 'object') { throw new Error('tfc is not an object'); }

    if (!self || !((self as any) instanceof Account)) { throw new Error('self is not an Account'); }

    const queryClient = self.queryClient;

    const subAccountId = SubAccountIdSchema.parse(
      self.subAccountId,
    );

    const userAccounts = UserAccountsSchema.parse(
      queryClient.getQueryData([userAccountsKey]),
    );

    const userAccount = userAccounts?.find(
      (account) => account.accountId == subAccountId,
    );
    if (userAccount == null) throw new Error('userAccount is null');

    const token = (await sdk.auth?.getAuthorizationBearer())?.replace('Bearer ', '');
    if (!token) {
      throw new Error('No aws token');
    }

    const ciqAction = transformCIQOrderAction(
      CIQOrderActionSchema.parse(ciqOrder.data.action),
    );

    const leverageInput = document.querySelector(
      `[${CHARTIQ_LEVERAGE_INPUT_ATTRIBUTE_NAME}]`,
    );

    if (!orderLeverage && leverageInput == null) return;

    const leverage =
      orderLeverage ?? parseInt((leverageInput as HTMLInputElement)?.value);

    if (!leverage || isNaN(leverage) || leverage <= 0 || leverage > 100) {
      cb();
      return;
    }

    const isShort =
      ciqOrder.data.action === 'cover' || ciqOrder.data.action === 'short';

    let takeProfit;
    let stopLoss;
    if ((ciqOrder.data.oto?.length ?? 0) > 0) {
      const lmtOrder = ciqOrder.data.oto?.find((oto) => 'limit' in oto);
      const stpOrder = ciqOrder.data.oto?.find((oto) => 'stop' in oto);

      stopLoss = stpOrder && 'stop' in stpOrder ? stpOrder?.stop : undefined;
      takeProfit =
        lmtOrder && 'limit' in lmtOrder ? lmtOrder?.limit : undefined;
    }

    let target: { type: 'LMT' | 'STP', value: number } | undefined;
    if (ciqOrder.data.limit != null || ciqOrder.data.stop != null) {
      target = {
        type: ciqOrder.data.limit != null ? 'LMT' : 'STP',
        value: (ciqOrder.data.limit ?? ciqOrder.data.stop) as number,
      };
    }

    const contract: Contract = {
      instrumentType: leverage ? 'CFD' : 'SPOT',
      underlyingId: self.underlyingId.toString(),
      underlyingSource: self.underlyingSource,
      direction: isShort ? 'short' : 'long',
    };

    const orderInfo: Order = {
      action: ciqAction,
      orderType: target ? target.type : 'MKT',
      tif: ciqOrder.data.tif,
      totalQuantity: ciqOrder.data.quantity,
      leverage,
      targetPrice: target ? target.value : undefined,
    };

    await trade(userAccount, contract, orderInfo, self.origin);

    // define tp and sl based on direction
    let newTakeProfit = takeProfit;
    let newStopLoss = stopLoss;

    if (isShort) {
      newTakeProfit = stopLoss;
      newStopLoss = takeProfit;
    }

    if (ciqAction === 'BUY') {
      if (newTakeProfit) {
        await trade(
          userAccount,
          contract,
          {
            ...orderInfo,
            orderType: 'LMT',
            targetPrice: newTakeProfit,
          },
          'web',
        );
      }

      if (newStopLoss) {
        await trade(
          userAccount,
          contract,
          {
            ...orderInfo,
            orderType: 'STP',
            targetPrice: newStopLoss,
          },
          'web',
        );
      }
    }
  } catch (err) {
    console.error(err);
  }
}
