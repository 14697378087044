// import lookupDriver from './lookupDriver';
import NameValueStore from './nameValueStore';

export const CHARTIQ_COMPARAISONS_COLORS = [
  '#f4977c',
  '#f7ac84',
  '#fbc58d',
  '#fff69e',
  '#c4de9e',
  '#85c99e',
  '#7fcdc7',
  '#75d0f4',
  '#81a8d7',
  '#8594c8',
  '#8983bc',
  '#a187bd',
  '#bb8dbe',
  '#f29bc1',
  '#ef6c53',
  '#f38d5b',
  '#f8ae63',
  '#fff371',
  '#acd277',
  '#43b77a',
  '#2ebbb3',
  '#00bff0',
  '#4a8dc8',
  '#5875b7',
  '#625da6',
  '#8561a7',
  '#a665a7',
  '#ee6fa9',
];

const config =
  {
    touchDevice: true,
    chartEngineParams: {
      layout: {
        chartType: 'candle',
        crosshair: false,
        periodicity: 1,
        interval: 'day',
      },
    },
    menuPeriodicity: [
      {
        type: 'item',
        label: '1 Sec',
        cmd: "Layout.setPeriodicity(1,1,'second')",
        value: { period: 1, interval: 1, timeUnit: 'second' },
      },
      {
        type: 'item',
        label: '10 Sec',
        cmd: "Layout.setPeriodicity(1,10,'second')",
        value: { period: 1, interval: 10, timeUnit: 'second' },
      },
      {
        type: 'item',
        label: '30 Sec',
        cmd: "Layout.setPeriodicity(1,30,'second')",
        value: { period: 1, interval: 30, timeUnit: 'second' },
      },
      { type: 'separator' },
      {
        type: 'item',
        label: '1 Min',
        cmd: "Layout.setPeriodicity(1,1,'minute')",
        value: { period: 1, interval: 1, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '5 Min',
        cmd: "Layout.setPeriodicity(1,5,'minute')",
        value: { period: 1, interval: 5, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '10 Min',
        cmd: "Layout.setPeriodicity(1,10,'minute')",
        value: { period: 1, interval: 10, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '15 Min',
        cmd: "Layout.setPeriodicity(3,5,'minute')",
        value: { period: 1, interval: 1, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '30 Min',
        cmd: "Layout.setPeriodicity(1,30,'minute')",
        value: { period: 1, interval: 30, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '1 Hour',
        cmd: "Layout.setPeriodicity(2,30,'minute')",
        value: { period: 2, interval: 30, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '4 Hour',
        cmd: "Layout.setPeriodicity(8,30,'minute')",
        value: { period: 8, interval: 30, timeUnit: 'minute' },
      },
      {
        type: 'item',
        label: '8 Hour',
        cmd: "Layout.setPeriodicity(16,30,'minute')",
        value: { period: 16, interval: 30, timeUnit: 'minute' },
      },
      { type: 'separator' },
      {
        type: 'item',
        label: '1 D',
        cmd: "Layout.setPeriodicity(1,1,'day')",
        value: { period: 1, interval: 1, timeUnit: 'day' },
      },
      {
        type: 'item',
        label: '1 W',
        cmd: "Layout.setPeriodicity(1,1,'week')",
        value: { period: 1, interval: 1, timeUnit: 'week' },
      },
      {
        type: 'item',
        label: '1 Mo',
        cmd: "Layout.setPeriodicity(1,1,'month')",
        value: { period: 1, interval: 1, timeUnit: 'month' },
      },
    ],
    themes: {
      defaultTheme: 'arlequin',
      NameValueStore,
    },
    enabledAddOns: {
      animation: true,
    },
  };

export default config;
