import { type SubAccountId, type Instrument, type UnderlyingId, type UnderlyingSource, sdk, type Origin } from '@arlequin-finance/af-frontend-sdk';
import React, { type PropsWithChildren, useState, useContext, useEffect } from 'react';

interface User {
  token: string | null
  subaccountId: SubAccountId | null
  origin: Origin | null
  product: {
    underlyingId: UnderlyingId | null
    underlyingSource: UnderlyingSource | null
    instrument: Instrument | null
  }
}

export const UserContext = React.createContext<User>({
  token: null,
  subaccountId: null,
  origin: null,
  product: {
    underlyingId: null,
    underlyingSource: null,
    instrument: null,
  },
});

export const UserProvider = ({ children }: PropsWithChildren): JSX.Element => {
  // get token from url
  const token = new URLSearchParams(window.location.search).get('token');// ?? 'eyJraWQiOiJGMjVGTG5yNzQ4VktTYVwvZDVQSGF2K2daNTVWUDl0eWdBZU9TWnc3YUNLST0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI2NzVhYWUwOC1iMzA0LTRjMzYtOGE5OS04MzQ5YzY4MzEzOTUiLCJkZXZpY2Vfa2V5IjoiZXUtd2VzdC0xX2VlZmE4OGRkLTU5ZGEtNGNjMy05YTMyLWIzNzU4N2E2MWQxYyIsImNvZ25pdG86Z3JvdXBzIjpbIkRldmVsb3BwZXJzIl0sImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS13ZXN0LTEuYW1hem9uYXdzLmNvbVwvZXUtd2VzdC0xX0h3QW1xTXJJayIsImNsaWVudF9pZCI6IjMxMGhpOWdiNW9jOWNtMWZxNWhlZmg3MGpzIiwib3JpZ2luX2p0aSI6IjVjY2NhODU4LWU1ZDAtNGExNy1iOWZjLWUwZTExYmMzYzhhMCIsImV2ZW50X2lkIjoiM2Y3OWExMzEtYzBkNC00M2M1LTk5OTgtMTg5ZmIyNGE3NjdjIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY5ODQxMTI2MywiZXhwIjoxNjk4NzQ4MjczLCJpYXQiOjE2OTg2NjE4NzMsImp0aSI6IjRkZjI5NWY3LWJlOGYtNDU3Yi1iMDBkLTE0YTM3OTYxY2I2MyIsInVzZXJuYW1lIjoiNjc1YWFlMDgtYjMwNC00YzM2LThhOTktODM0OWM2ODMxMzk1In0.QD8y5BqowX_gJu3rR4tUYk1HFE1lixuR9fc_XfZ7zDsL7x2WvPupMBe2Zox0AgLfpBUxAEMdxHsqDMtvU321SC86MAwwHlxpknBAV2BAKPVrSSvbPUamMD3HIfn9BQFe3MJQLQay-iEs_QTJuLnj_75BK5L5hhHKnHS2Fy3FBoqmGH4Yes5BstYWM3newa_-oMSFkEyKrslRQzJ65uTBerhE5EhbOmkor-FunMeS-YpwgzyGOrOwmcoM4erQ5mbrFrEx6xhYzhDKq2XVltad-LhRrBUG0yGUWKGLxY-_KF1-5Fs8tATy2YdafZrW1miGx925opIIh5qZznQ9qv1A7g';
  const subaccountId = new URLSearchParams(window.location.search).get('subaccountId');// ?? 32891;
  const origin = new URLSearchParams(window.location.search).get('origin');// ?? WEB;
  const product = new URLSearchParams(window.location.search).get('product');// ?? '{"underlyingId":"8327414","underlyingSource":"factset","instrument":{"cfdAuthorized":true,"currency":"JPY","derivativeInfos":{},"description":"","fullName":"Canadian Dollar / Japanese Yen (CAD/JPY)","ibkrExchangeName":"FX","ibkrInstrumentType":"CASH","instrumentCategory":"National Currency Cross-Rate","instrumentId":"8327414","instrumentLogoUrl":"","isin":"XC0006169699","market":"FactSet Forex Rates","priceDecimals":5,"priceSource":"factset","shortName":"CAD/JPY","symbol":null,"timestamp":"2023-09-12T14:15:36.266171"}}';
  const jsonParsedProduct = product != null ? JSON.parse(product) : null;

  sdk.auth = {
    getAuthorizationBearer: async () => {
      return `Bearer ${token}`;
    },
    getExpiration: async () => new Date().getTime() + 1000 * 60 * 60 * 24, // cognito configuration
    getAccessToken: async () => token,

  } as any;

  const [user, setUser] = useState<User>({
    token,
    subaccountId,
    origin: null,
    product: {
      underlyingId: null,
      underlyingSource: null,
      instrument: null,
    },
  });

  useEffect(() => {
    setUser({
      product: jsonParsedProduct,
      token,
      subaccountId,
      origin: origin === null ? 'web' : origin as Origin,
    });
  }, [token, subaccountId, product]);

  return (
    <UserContext.Provider value={user}>{children}</UserContext.Provider>
  );
};

export const useUser = (): User => useContext(UserContext);
