import { z } from 'zod';
import { type Account } from '../Account';
import { placeCIQOrder } from './placeOrder';
import { OrderValiditySchema, SubAccountIdSchema, PositionsSchema, positionKey } from '@arlequin-finance/af-frontend-sdk';

const ciqProtectionSchema = z.object({
  symbol: z.string(),
  quantity: z.number(),
  action: z.enum(['buy', 'sell', 'short', 'cover']),
  limit: z.number().optional(),
  stop: z.number().optional(),
  tif: OrderValiditySchema,
  tradeid: z.string(),
});

const ciqProtectionsSchema = z.array(ciqProtectionSchema);

export const placeProtection = async (
  tfc: unknown,
  protections: unknown,
  cb: unknown,
  self: Account,
): Promise<void> => {
  const ciqOrder = ciqProtectionsSchema.safeParse(protections);
  if (!ciqOrder.success) throw new Error('invalid order schema');

  const queryClient = self.queryClient;

  const subAccountId = SubAccountIdSchema.parse(
    self.subAccountId,
  );

  const positions = PositionsSchema.parse(
    queryClient.getQueryData([positionKey, subAccountId]),
  );

  ciqOrder.data.forEach((protection) => {
    const selectedPosition = positions.find(
      (position) => position.position_key === protection.tradeid,
    );

    if (selectedPosition == null) throw new Error('selectedPosition is null');

    let limit = protection.limit;
    let stop = protection.stop;

    if (protection.action === 'cover') {
      limit = protection.stop;
      stop = protection.limit;
    }

    placeCIQOrder(
      tfc,
      {
        ...protection,
        type: 'order',
        quantity: selectedPosition.cumulated_quantity,
        stop,
        limit,
      },
      cb,
      self,
      selectedPosition.leverage,
    ).catch((error) => {
      console.error('error', error);
    });
  });
};
