import React, { type PropsWithChildren } from 'react';
import { useUser } from '../Providers/userProvider';

export default function ChartLoader({ children }: PropsWithChildren): JSX.Element {
  const user = useUser();

  if (user == null) {
    return <div>Loading...</div>;
  }

  if (user.product == null) {
    return <div>Product not found</div>;
  }

  if (user.product.underlyingId == null) {
    return <div>UnderlyingId not found</div>;
  }

  if (user.product.underlyingSource == null) {
    return <div>UnderlyingSource not found</div>;
  }

  if (user.subaccountId == null) {
    return <div>SubaccountId not found</div>;
  }

  if (user.token == null) {
    return <div style={{
      color: 'white',
    }}> Auth not found</div>;
  }

  return <>{children}</>;
}
