import { z } from 'zod';
import { ActionSchema } from './common';
import { OrderValiditySchema, CurrentOrderSchema } from '@arlequin-finance/af-frontend-sdk';

export const CIQAccountOrderSchema = z.object({
  id: z.string(),
  action: ActionSchema,
  quantity: z.number(),
  tif: OrderValiditySchema,
  currency: z.string(),
  stop: z.number().optional(),
  limit: z.number().optional(),
  // custom
  currentOrder: CurrentOrderSchema,
});
export type CIQAccountOrder = z.infer<typeof CIQAccountOrderSchema>;

export const CIQAccountOrdersSchema = z.record(
  z.string(),
  z.array(CIQAccountOrderSchema),
);
export type CIQAccountOrders = z.infer<typeof CIQAccountOrdersSchema>;
