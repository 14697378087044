import { CIQ } from 'chartiq';
import { replaceCIQOrder } from './functions/replaceOrder';
import { cancelCIQOrder } from './functions/cancelOrder';
import { type QueryClient } from '@tanstack/react-query';
import { closeAllCIQOrder } from './functions/closeAllPositions';
import { placeCIQOrder } from './functions/placeOrder';
import { closeTrades } from './functions/closeTrade';
import { placeProtection } from './functions/placeProtection';
import { type UnderlyingId, type Positions, type UnderlyingSource, type SubAccountId, type Origin } from '@arlequin-finance/af-frontend-sdk';
import { type CIQAccountOrders } from '../types/order';

export interface AccountPosition {
  quantity: number
  basis: number
  price: number
  currency: string
  prevClose?: number
  // custom
  positions?: Positions
}

export type AccountPositions = Record<string, AccountPosition>;

export class Account {
  subAccountId: SubAccountId;

  origin: Origin;

  underlyingId: UnderlyingId;

  underlyingSource: UnderlyingSource;

  queryClient: QueryClient;

  currency: string;

  balances: {
    liquidity: number | undefined
    unsettledCash: number | undefined
    cash: number | undefined
    profitLoss: number | undefined
    buyingPower: number | undefined
  };

  positions: AccountPositions;

  openOrders: CIQAccountOrders;

  Poller: any;

  trades: any;

  config: any;

  tradability: any;

  constructor(
    subAccountId: SubAccountId,
    underlyingId: UnderlyingId,
    underlyingSource: UnderlyingSource,
    queryClient: QueryClient,
  ) {
    this.subAccountId = subAccountId;
    this.origin = 'web';
    this.underlyingId = underlyingId;
    this.underlyingSource = underlyingSource;
    this.queryClient = queryClient;

    this.currency = 'EUR';
    this.config = {
      oto: true,
      oco: true,
      closeAll: true,
      tradeActions: true,
      reducePositions: true,
      vsp: '',
      showOpenOrdersWhenTFCClosed: true,
      gtcOnly: true,
    };

    this.balances = {
      liquidity: 0,
      unsettledCash: 0,
      cash: 0,
      profitLoss: 0,
      buyingPower: 0,
    };

    this.positions = {};
    this.openOrders = {};
    this.trades = {};

    this.Poller = (CIQ as any).Account.prototype.getPoller();
    this.Poller.intervals = {
      account: { timer: null, poll: 1_000 },
    };
    this.tradability = (symbol: any, cb: any) => {
      cb({
        tradable: true,
        shortable: true,
        marketable: true,
      });
    };
  }

  replaceOrder(...args: [unknown, unknown, unknown]): void {
    replaceCIQOrder(...args, this);
  }

  cancelOrder(...args: [unknown, unknown, unknown]): void {
    cancelCIQOrder(...args, this);
  }

  closeAllPositions(...args: [unknown, unknown]): void {
    closeAllCIQOrder(...args, this);
  }

  placeOrder = (...args: [unknown, unknown, unknown]): void => {
    placeCIQOrder(...args, this).catch((error) => { console.error('error', error); });
  };

  setProtection = (...args: [unknown, unknown, unknown]): void => {
    placeProtection(...args, this).catch((error) => { console.error('error', error); });
  };

  closeTrade = (...args: [unknown, unknown, unknown]): void => {
    closeTrades(...args, this);
  };

  closePositions(tfc: unknown, position: unknown, cb: () => void): void {
    cb();
  }

  confirmOrder(tfc: unknown, order: unknown, cb: () => void): void {
    cb();
  }
}
