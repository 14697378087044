import { type UnderlyingId, useInstrument, formatPrice, type UnderlyingSource } from '@arlequin-finance/af-frontend-sdk';
import React, { useCallback, useEffect } from 'react';
import { DEFAULT_LEVERAGE } from '../utils/constants';

export const CHARTIQ_LEVERAGE_INPUT_ATTRIBUTE_NAME = 'data-chartiq-leverage';

interface ChartObserverProps {
  chartRef: any
  underlyingId: UnderlyingId
  underlyingSource: UnderlyingSource
}

export default function ChartObserver(props: ChartObserverProps): JSX.Element {
  const stx = props.chartRef?.stx;

  const { data: instrument } = useInstrument(
    props.underlyingId,
    props.underlyingSource,
  );

  const renderPrice = useCallback(() => {
    const tfc = stx?.tfc;

    // #region get new gainLoss elements
    const chartPanel = document.querySelector(
      '.stx-holder.stx-panel-chart',
    ) as HTMLDivElement;

    if (!chartPanel) return;

    const existingBelowGainAmount = chartPanel.querySelector(
      '.stx-data.tfc-gain-amount.tfc-gain-custom.below',
    ) as HTMLDivElement;

    const existingAboveGainAmount = chartPanel.querySelector(
      '.stx-data.tfc-gain-amount.tfc-gain-custom.above',
    ) as HTMLDivElement;

    // #endregion

    // #region calculate new gainLoss

    const quantity = tfc.quantityFromValue(tfc.elements.limitShares.value);

    const leverageElement = document.querySelector(
      `[${CHARTIQ_LEVERAGE_INPUT_ATTRIBUTE_NAME}]`,
    );

    if (leverageElement == null) return;
    if (!('value' in leverageElement)) return;
    if (typeof leverageElement.value !== 'string') return;
    if (isNaN(parseInt(leverageElement.value))) return;

    let leverage = parseInt(leverageElement.value);

    let price = tfc.centerPrice;

    const position = tfc.account.positions[tfc.stx.chart.symbol];
    if (
      tfc.activeTrade == 'bracket_sell' ||
      tfc.activeTrade == 'bracket_cover'
    ) {
      leverage = 1;
      if (tfc.elements.bracketId.value) {
        const trades = tfc.account.trades[tfc.stx.chart.symbol];
        for (let t = 0; t < trades.length; t++) {
          if (trades[t].id == tfc.elements.bracketId.value) { price = trades[t].basis; }
        }
      } else if (position) price = Math.abs(position.basis);
    }

    const amount = quantity * price;
    const abovePrice = tfc.abovePrice;
    const belowPrice = tfc.belowPrice;

    const limitAmount = quantity * abovePrice;
    const stopAmount = quantity * belowPrice;

    let limitGainLoss = (limitAmount - amount) * leverage;
    let stopGainLoss = (stopAmount - amount) * leverage;

    if (tfc.activeTrade == 'short' || tfc.activeTrade == 'bracket_cover') {
      limitGainLoss = (amount - limitAmount) * leverage;
      stopGainLoss = (amount - stopAmount) * leverage;
    }

    const limitGainLossText =
      formatPrice(
        limitGainLoss,
        instrument?.currency,
        navigator.language,
        instrument?.priceDecimals,
        true,
      ) ?? '';

    const stopGainLossText =
      formatPrice(
        stopGainLoss,
        instrument?.currency,
        navigator.language,
        instrument?.priceDecimals,
        true,
      ) ?? '';

    if (stopGainLoss != null && !isNaN(stopGainLoss)) {
      if (existingBelowGainAmount) {
        existingBelowGainAmount.innerHTML = stopGainLossText;
      } else {
        const newBelowGainAmount = document.createElement('div');
        newBelowGainAmount.innerHTML = stopGainLossText;
        newBelowGainAmount.className =
          'stx-data tfc-gain-amount tfc-gain-custom below';

        tfc.elements.belowGainAmount.insertAdjacentElement(
          'afterend',
          newBelowGainAmount,
        );
      }
    }

    if (limitGainLoss != null && !isNaN(limitGainLoss)) {
      if (existingAboveGainAmount) {
        existingAboveGainAmount.innerHTML = limitGainLossText;
      } else {
        const newAboveGainAmount = document.createElement('div');
        newAboveGainAmount.innerHTML = limitGainLossText;
        newAboveGainAmount.className =
          'stx-data tfc-gain-amount tfc-gain-custom above';

        tfc.elements.aboveGainAmount.insertAdjacentElement(
          'afterend',
          newAboveGainAmount,
        );
      }
    }
  }, [instrument?.currency, instrument?.priceDecimals, stx?.tfc]);

  const observerInnerHtmlCallback = useCallback(
    () => {
      try {
        renderPrice();
      } catch (error) {
        console.error('error on observerInnerHtmlCallback', error);
      }
    },
    [renderPrice],
  );

  useEffect(() => {
    const textsElements = document.querySelectorAll(
      `.stx-data.tfc-gain-amount, [${CHARTIQ_LEVERAGE_INPUT_ATTRIBUTE_NAME}]`,
    );

    const observers: MutationObserver[] = [];

    const config: MutationObserverInit = {
      characterData: false,
      childList: true,
      attributes: false,
      subtree: false,
    };

    for (const element of textsElements) {
      const observer = new MutationObserver(observerInnerHtmlCallback);
      observer.observe(element, config);
    }

    return () => {
      observers.forEach((observer) => { observer.disconnect(); });
    };
  }, [observerInnerHtmlCallback]);

  useEffect(() => {
    const inputElement = document.querySelectorAll(
      `[${CHARTIQ_LEVERAGE_INPUT_ATTRIBUTE_NAME}]`,
    );

    if (!inputElement) return;

    for (const element of inputElement) {
      element.addEventListener('change', () => { renderPrice(); });
    }

    return () => {
      inputElement.forEach((element) => { element.removeEventListener('change', () => { renderPrice(); }); },
      );
    };
  }, [renderPrice]);

  useEffect(() => {
    const elements = document.querySelectorAll(
      '.tfc.create-order .inputTemplate.amount',
    );

    elements.forEach((element) => {
      if (
        element.parentNode?.querySelectorAll(
          `[${CHARTIQ_LEVERAGE_INPUT_ATTRIBUTE_NAME}]`,
        ).length ??
        1 > 0
      ) { return; }

      const leverageField = document.createElement('div');
      leverageField.id = 'chartIQ_leverage';
      leverageField.className = 'inputTemplate leverage';

      leverageField.innerHTML = `
        <div class="stx-data">
          <input
            ${CHARTIQ_LEVERAGE_INPUT_ATTRIBUTE_NAME}="true"
            class="tfc-currency stx-input-field"
            value="${DEFAULT_LEVERAGE}"
            placeholder=""
            type="text"
            onkeyup="(function(){
              document.querySelectorAll('[${CHARTIQ_LEVERAGE_INPUT_ATTRIBUTE_NAME}]').forEach((element)=>{
                element.value = event.target.value
              })
            })()">
          </div>
          <div class="stx-label">Leverage</div>
      `;
      element.insertAdjacentElement('afterend', leverageField);
    });

    // click on "open position by trade" button because the default selected button is hidden now
    const positionByTradeButton = document.querySelector(
      '.tfc-positions-view.lots',
    );
    if (!positionByTradeButton) return;
    if (!('click' in positionByTradeButton)) return;
    if (typeof positionByTradeButton.click !== 'function') return;
    positionByTradeButton.click();
  }, []);

  return <></>;
}
