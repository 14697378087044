import React, { type PropsWithChildren } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Query, queryClient } from '@arlequin-finance/af-frontend-sdk';

export default function TanstackQuery(props: PropsWithChildren): JSX.Element {
  return (
    <QueryClientProvider client={queryClient.get}>
      <Query queryClient={queryClient.get} devTools={false}>
        {props.children}
        </Query>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
