import { z } from 'zod';

import { Account } from '../Account';

import { CurrentOrderSchema, OrderValiditySchema, SubAccountIdSchema, UserAccountsSchema, cancelOrder, userAccountsKey } from '@arlequin-finance/af-frontend-sdk';

export const CIQCancelOrderSchema = z.object({
  id: z.string(),
  action: z.union([z.literal('buy'), z.literal('sell')]),
  quantity: z.number(),
  currency: z.string(),
  tif: OrderValiditySchema,
  limit: z.number().optional(),
  stop: z.number().optional(),
  // custom
  currentOrder: CurrentOrderSchema,
});

export type CIQCancelOrder = z.infer<typeof CIQCancelOrderSchema>;

export function cancelCIQOrder(
  tfc: unknown,
  order: unknown,
  cb: unknown,
  self: Account,
): void {
  try {
    if (typeof cb !== 'function') throw new Error('callback is not a function');

    const ciqOrder = CIQCancelOrderSchema.safeParse(order);

    if (!ciqOrder.success) throw new Error('invalid order schema');

    if (!tfc || typeof tfc !== 'object') { throw new Error('tfc is not an object'); }

    if (!('modifyingOrder' in tfc) || typeof tfc.modifyingOrder !== 'object') { throw new Error('tfc.modifyingOrder is not an object'); }

    const modifyingOrder = CIQCancelOrderSchema.safeParse(tfc.modifyingOrder);

    if (!modifyingOrder.success) { throw new Error('invalid modifyingOrder schema'); }

    if (!self || !((self as any) instanceof Account)) { throw new Error('self is not an Account'); }

    const queryClient = self.queryClient;

    const subAccountId = SubAccountIdSchema.parse(
      self.subAccountId,
    );

    const userAccounts = UserAccountsSchema.parse(
      queryClient.getQueryData([userAccountsKey]),
    );

    const userAccount = userAccounts?.find(
      (account) => account.accountId == subAccountId,
    );
    if (userAccount == null) throw new Error('userAccount is null');

    cancelOrder(
      userAccount,
      ciqOrder.data.currentOrder,
      self.origin,
    ).then(cb()).catch((error) => {
      console.error('cancelCIQOrder :', error);
    });
  } catch (error) {
    console.error('replaceCIQOrder :', error);
    alert(error);
  }
}
