
const BASE_KEY = 'arlequin-chart-store_';

class NameValueStore {
  public get = function (
    key: string,
    callback: (error: any, data: any) => void,
  ): any {
    const storeKey = BASE_KEY + key;
    const value = JSON.parse(localStorage.getItem(storeKey) ?? '{}');
    callback(false, value);
    return value;
  };

  public set = function (key: string, value: any): any {
    const storeKey = BASE_KEY + key;
    localStorage.setItem(storeKey, JSON.stringify(value));
    return value;
  };

  public remove = function (
    key: string,
    callback: (error: any, data: any) => void,
  ): any {
    const storeKey = BASE_KEY + key;
    const callbackData = localStorage.getItem(storeKey);
    localStorage.removeItem(storeKey);
    callback(false, callbackData);
  };
}
export default new NameValueStore();
